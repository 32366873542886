import { Notify } from 'quasar';
import { Screen } from 'quasar';
import { version } from '/package.json';

// - 取得當前視窗尺寸
export const successGetCurrentSysInfo = () => {
  Notify.create({
    message: 'Screen : ' + Screen.width + ' x ' + Screen.height,
    type: 'positive',
    timeout: 10000,
    position: 'bottom-right',
    actions: [
      {
        icon: 'close',
        color: 'white',
        round: true,
        handler: () => {
          /* ... */
        }
      }
    ]
  });
  Notify.create({
    message: 'System Version: ' + version,
    type: 'info',
    timeout: 10000,
    position: 'bottom-right',
    actions: [
      {
        icon: 'close',
        color: 'white',
        round: true,
        handler: () => {
          /* ... */
        }
      }
    ]
  });
};

// - 提示回報錯誤
export const hintReportError = async () => {
  const { useReportError } = await import('@/stores/report-error');
  const reportError = useReportError();

  Notify.create({
    message: '遇到錯誤了嗎？ 請點此查看詳情',
    type: 'warning',
    timeout: 6000,
    position: 'top',
    actions: [
      {
        closeBtn: true,
        label: '取消',
        color: 'black'
      },
      {
        label: '查看詳情',
        handler: () => {
          reportError.dialog = true;
        }
      }
    ]
  });
};

// - 驗證身分失敗
export const errorAuthFailed = () => {
  Notify.create({
    message: '密碼錯誤',
    type: 'negative'
  });
};

// - 待辦事項
export const alertNotAvailable = () => {
  Notify.create({
    message: '此事件已經被取消或已逾期',
    type: 'negative'
  });
};
export const acceptApplication = () => {
  Notify.create({
    message: '通過申請',
    type: 'positive'
  });
};
export const rejectReapplyApplication = () => {
  Notify.create({
    message: '要求重新送件',
    type: 'warning'
  });
};
export const rejectAddExplainApplication = () => {
  Notify.create({
    message: '要求補充說明',
    type: 'warning'
  });
};
export const successMarkLeaveInvalid = () => {
  Notify.create({
    message: '標記銷假成功',
    type: 'positive'
  });
};

// - 公司資料
export const successEditedCoData = () => {
  Notify.create({
    message: '修改公司資料成功',
    type: 'positive'
  });
};

// - 部門設定
export const successAddDept = () => {
  Notify.create({
    message: '新增部門成功',
    type: 'positive'
  });
};
export const errorDuplicateDeptId = () => {
  Notify.create({
    message: '部門編號重複',
    type: 'negative'
  });
};
export const errorManagerIdNotExist = () => {
  Notify.create({
    message: '部門編號重複',
    type: 'negative'
  });
};

export const successEditedDeptdata = () => {
  Notify.create({
    message: '修改部門資料成功',
    type: 'positive'
  });
};

// - 職稱設定
export const successAddGrade = () => {
  Notify.create({
    message: '新增職稱成功',
    type: 'positive'
  });
};

export const successEditedGradeData = () => {
  Notify.create({
    message: '修改職稱資料成功',
    type: 'positive'
  });
};

// - 僱傭別設定
export const successAddType = () => {
  Notify.create({
    message: '新增僱傭別成功',
    type: 'positive'
  });
};
export const successEditedTypeData = () => {
  Notify.create({
    message: '修改僱傭別資料成功',
    type: 'positive'
  });
};

// - 國籍設定
export const successAddNation = () => {
  Notify.create({
    message: '新增國籍成功',
    type: 'positive'
  });
};

export const successEditedNationData = () => {
  Notify.create({
    message: '修改國籍資料成功',
    type: 'positive'
  });
};

// - 節假日設定
export const successPatchHolidayNickname = () => {
  Notify.create({
    message: '修改節假日名稱成功',
    type: 'positive'
  });
};

// - 員工資料
export const successAddEmpdata = () => {
  Notify.create({
    message: '新增員工成功',
    type: 'positive'
  });
};
export const successUnbindEmpSys = () => {
  Notify.create({
    message: '成功解除綁定 EMP 系統帳號',
    type: 'positive'
  });
};

export const successResignEmp = () => {
  Notify.create({
    message: '標記員工離職成功',
    type: 'positive'
  });
};
export const alreadyResign = () => {
  Notify.create({
    message: '該員工已經標記過離職',
    type: 'warning'
  });
};

export const successEditedEmpdata = () => {
  Notify.create({
    message: '修改員工資料成功',
    type: 'positive'
  });
};

export const successPatchEmpPos = () => {
  Notify.create({
    message: '修改員工職務成功',
    type: 'positive'
  });
};

export const empAlreadyExist = () => {
  Notify.create({
    message: '員工編號已存在',
    type: 'negative'
  });
};

// - 合約設定
export const successAddContr = () => {
  Notify.create({
    message: '新增合約成功',
    type: 'positive'
  });
};

export const successDeleteContr = () => {
  Notify.create({
    message: '合約刪除成功',
    type: 'negative'
  });
};

// - 排班設定
export const successEditContr = () => {
  Notify.create({
    message: '排班資訊修改成功',
    type: 'positive'
  });
};

export const successPostShiftTemplate = () => {
  Notify.create({
    message: '排班模板新增成功',
    type: 'positive'
  });
};

export const successPatchShiftTemplate = () => {
  Notify.create({
    message: '排班模板修改成功',
    type: 'positive'
  });
};

export const successDeleteShiftTemplate = () => {
  Notify.create({
    message: '排班模板刪除成功',
    type: 'negative'
  });
};

export const successAddShift = () => {
  Notify.create({
    message: '新增排班資訊成功',
    type: 'positive'
  });
};

export const successPatchShift = () => {
  Notify.create({
    message: '修改排班資訊成功',
    type: 'positive'
  });
};

// - 請假與加班
export const successAddLeavRec = () => {
  Notify.create({
    message: '新增請假紀錄成功',
    type: 'positive'
  });
};

export const successAddOtRec = () => {
  Notify.create({
    message: '新增加班紀錄成功',
    type: 'positive'
  });
};

// - 薪資調整
export const successSalaryAdjust = () => {
  Notify.create({
    message: '薪資調整成功',
    type: 'positive'
  });
};

// - 其他
export const successCopyToClipboard = () => {
  Notify.create({
    message: '複製成功',
    type: 'positive',
    timeout: 500
  });
};

// - 表單驗證錯誤欄位提示
export const formValidFailedFields = (field) => {
  Notify.create({
    message: `請檢查欄位: ${field}`,
    type: 'info',
    timeout: 1000,
    position: 'bottom'
  });
};
export const formValidFailedFieldsEN = (field) => {
  Notify.create({
    message: `Please check the field: ${field}`,
    type: 'info',
    timeout: 1000,
    position: 'bottom'
  });
};
