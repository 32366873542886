import { createApp } from 'vue';
import App from '@/App.vue';
import pinia from '@/stores';
import router from '@/router';
import api from '@/axios';

import { version } from '../package.json';

// - Sentry
import * as Sentry from '@sentry/vue';
import {
  captureConsoleIntegration,
  extraErrorDataIntegration
} from '@sentry/integrations';

// - Quasar
import { Quasar, Notify } from 'quasar';
import quasarLang from 'quasar/lang/zh-TW';
import quasarIconSet from 'quasar/icon-set/svg-material-icons';

// - q-table排序
quasarIconSet.table.arrowUp = 'mdi-arrow-collapse-up';

// - icon集
import '@quasar/extras/material-icons/material-icons.css';
import '@quasar/extras/material-icons-outlined/material-icons-outlined.css';
import '@quasar/extras/material-icons-round/material-icons-round.css';
import '@quasar/extras/material-icons-sharp/material-icons-sharp.css';
import '@quasar/extras/material-symbols-outlined/material-symbols-outlined.css';
import '@quasar/extras/material-symbols-rounded/material-symbols-rounded.css';
import '@quasar/extras/material-symbols-sharp/material-symbols-sharp.css';
import '@quasar/extras/mdi-v7/mdi-v7.css';

// - sass集
import '@/../node_modules/quasar/src/css/index.sass';

// - Calendar
import Plugin from '@quasar/quasar-ui-qcalendar/src/QCalendarDay.js';
import '@quasar/quasar-ui-qcalendar/src/css/calendar-day.sass';

// - 自定義全域SCSS
import '@/assets/styles/all.scss';

// - 全域 Components
import TitleSeparator from '@/components/TitleSeparator.vue';
import ReportErrorDialog from '@/components/ReportErrorDialog.vue';
import JsonExcel from 'vue-json-excel3';

const app = createApp(App);
app.provide('api', api);

if (process.env.NODE_ENV === 'staging') {
  Sentry.init({
    app,
    dsn: 'https://94890ab1f2f5c6e30c6f5c816efe814d@o4506678292840448.ingest.sentry.io/4506684438413312',
    environment: process.env.NODE_ENV,
    release: 'hr-system@' + version,
    normalizeDepth: 10,
    integrations: [
      captureConsoleIntegration({
        levels: ['error', 'debug', 'assert']
      }),
      extraErrorDataIntegration({
        depth: 10
      }),

      Sentry.browserTracingIntegration(),

      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      "Failed to execute 'getComputedStyle' on 'Window': parameter 1 is not of type 'Element'.",
      'Error: ResizeObserver loop completed with undelivered notifications.'
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

app.use(pinia);
app.use(router);
app.use(Plugin);
app.use(Quasar, {
  plugins: { Notify },
  lang: quasarLang,
  iconSet: quasarIconSet,
  config: {
    brand: {
      primary: '#1976d2',
      secondary: '#26a69a',
      accent: '#9c27b0',
      dark: '#1d1d1d',
      positive: '#21ba45',
      negative: '#c10015',
      info: '#31ccec',
      warning: '#f2c037'
    }
  }
});

// - 自定義Component
app.component('TitleSeparator', TitleSeparator);
app.component('ReportErrorDialog', ReportErrorDialog);
app.component('DownloadExcel', JsonExcel);

app.mount('#app');
